.quiz-loader {
  &__message {
    font-size: 2rem;
    font-weight: 700;
    color: #1D4B81;
    margin-bottom: 2rem;
    text-align: center;
  }
  &__bar {
    position: relative;
    width: 100%;
    height: 2rem;
    border-radius: 1.5rem;
    overflow: hidden;
    border-bottom: 0.1rem solid #ddd;
    box-shadow: inset 0 0.2rem 0.9rem rgba(255, 255, 255, 0.3), inset 0 -0.2rem 0.6rem rgba(0, 0, 0, 0.08);
    background: #eaedef;
    .holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      animation: test 4s forwards;
      height: 100%;
      background-color: #eaedef;
      transform-origin: right 0;
    }
    .percentage {
      position: absolute;
      top: 0; left: 0; right:0;
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 1.5rem;
      transform: scaleX(1);
      background-color: #2bc253;
      background-size: 3rem 3rem;
      box-shadow: inset 0 0.2rem 0.9rem rgba(255, 255, 255, 0.3), inset 0 -0.2rem 0.6rem rgba(0, 0, 0, 0.4);
      background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
      animation: animate-stripes 3s linear infinite;
    }
    @keyframes animate-stripes {
      100% { background-position: 0 0;}
      0% { background-position: 6rem 0;}
    }
    @keyframes test {
      0% {transform: scaleX(1);}
      100% {transform: scaleX(0);}
    }
  }
}

.animation-enter-active, .animation-leave-active {
  transition: opacity 0.2s ease-in-out, transform 0.2s ease;
}
.animation-enter-active {
  transition-delay: 0.2s;
}
.animation-enter, .animation-leave-to {
  opacity: 0;
  transform: translateX(-1rem);
}
.animation-enter-to, .animation-leave {
  opacity: 1;
  transform: translateX(0);
}

.fixed-height {
  height: 42rem;
  @media(max-width: 768px) {
    height: 38rem;
  }
  &--input {
    height: 24rem;
    @media(max-width: 768px) {
      height: 22rem;
    }
  }
  &--loader {
    height: 12rem;
  }
}
