.progress-bar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}

.progress-bar::-webkit-progress-bar {
  background: #EDEDED;
  border: 0;
  height: 5px;
  border-radius: 10px;
}

.progress-bar::-ms-fill {
    border: none;
    border-radius: 10px;
}

.progress-bar::-webkit-progress-value {
  background: #1EA73C;
  border: 0;
  height: 5px;
  border-radius: 10px;
  transition: width 1s ease;
}