// animations
.fade-enter,
.fade-leave-to {
  opacity: 0
}

.fade-leave,
.fade-enter-to {
  opacity: 1
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms
}


.scale-bottom-right-leave-to,
.scale-bottom-left-enter,
.scale-bottom-left-leave-to
{ transform: scale(0.1) }

.scale-bottom-right-leave,
.scale-bottom-right-enter-to,
.scale-bottom-left-leave,
.scale-bottom-left-enter-to
{ transform: scale(1) }

.scale-bottom-right-enter-active,
.scale-bottom-right-leave-active, {
  transition: transform 300ms;
  transform-origin: bottom right;
}

.scale-bottom-left-enter-active,
.scale-bottom-left-leave-active {
  transition: transform 300ms;
  transform-origin: bottom left;
}

.scale-top-right-enter,
.scale-top-right-leave-to {
  transform: scale(0.1)
}

.scale-top-right-leave,
.scale-top-right-enter-to {
  transform: scale(1)
}

.scale-top-right-enter-active,
.scale-top-right-leave-active {
  transition: transform 300ms;
  transform-origin: top right;
}
.scale-in-enter,
.scale-in-leave-to {
  opacity: 0;
  transform: scale(0.4);
  transform-origin: center;
  position: absolute;
}

.scale-in-leave-active {
  transition: 0s;
}
.scale-in-enter-active {
  transition: 0.2s;
}

.animated {
  transition: 0.3s ease;
}
.group {
  > .animated {
    &--expandable {
      left: 50%;
      width: 0;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
    }
  }
  &:hover {
    > .animated {
      &--expandable {
        width: 100%;
        left: 0;
      }
    }
  }
}
.scale-center-enter,
.scale-center-leave-to {
  transform: scale(0.1)
}

.scale-center-leave,
.scale-center-enter-to {
  transform: scale(1)
}

.scale-center-enter-active,
.scale-center-leave-active {
  transition: transform 200ms ease, opacity 600ms ease;
  transform-origin: center center;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -120%, 0);
    visibility: visible;
    opacity: 0.3;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInDown {
  animation-name: slideInDown;
  animation-duration: 600ms;
}
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, -120%, 0);
    opacity: 0.3;
    visibility: visible;
  }
}

.slideOutUp {
  animation-name: slideOutUp;
  animation-duration: 600ms;
}

.slide-down-enter,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.slide-down-leave-active {
  transition: 0.1s;
  height: 0;
}

.slide-down-enter-active {
  transition: 0.3s;
  height: auto;
  position: relative;
}
.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
  width: 100%;
  max-width: 100%;
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
  width: 100%;
}
