.title-bg {
  opacity: 0.95;
  &--blue {
    background: #2353A4;
  }
  &--orange {
    background: #D1530B;
  }
  &--purple {
    background: #8D4A6C;
  }
}
.background {
  background-image: url('/images/dash-bg1.jpg');
  background-size: cover;
  @media(max-width: 768px) {
    background-image: url('/images/dash-bg2.jpg');
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
  }
  &--second {
    background-image: url('/images/dash-bg3.png');
  }
  @media(max-width: 768px) {
    background-image: url('/images/dash-bg4.jpg');
  }
}


.recipe-item {
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  .recipe-icon {
    opacity: 0;
    width: 0;
  }
  > span {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    cursor: pointer;
    > span {
      @apply min-h-full;
    }
    .recipe-text {
      opacity: 0;
      width: 0;
    }
    .recipe-icon {
      opacity: 1;
      width: 100%;
    }
  }
}

$base-line-height: 24px;
$color: theme('colors.primary.default');
$spin-duration: 1s;
$pulse-duration: 750ms;
.loading-pulse {
  position: relative;
  width: ($base-line-height / 4);
  height: $base-line-height;
  background: $color;
  animation: pulse $pulse-duration infinite;
  animation-delay: ($pulse-duration / 3);
  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    height: ($base-line-height / 1.5);
    width: ($base-line-height / 4);
    background: $color;
    top: 50%;
    transform: translateY(-50%);
    animation: pulse $pulse-duration infinite;
  }
  &:before {
    left: -($base-line-height / 2);
  }
  &:after {
    left: ($base-line-height / 2);
    animation-delay: ($pulse-duration / 1.5);
  }
}

.flip, .flippable {
  transition: all .2s ease;
  transform: rotate(180deg);
}

.flip {
  transform: rotate(0deg);
}

.remote-list {
 list-style-type: none;
 li {
   strong {
     width: 2rem;
     display: inline-block;
      + p {
        display: inline-block;
        width: calc(100% - 2rem);
      }
   }
 }
}


@keyframes pulse {
  50% {
    background: $color;
    opacity: 0.2;
  }
}

@responsive {
  .w-1\/8 {
    width: 12.5%
  }
  .w-1\/7 {
    width: 14.285%
  }
}

.meal{
  &__title {
    @media (min-width: 1024px) {
        height: 66px;
        overflow: hidden;
    }
  }
}

.modal-text .ingredientscss p:first-of-type {
  display: none;
}

select::-ms-expand {
  display: none;
}


.orange-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.5rem 2rem 0 0;
  border-color: #dd8819 transparent transparent transparent;
  line-height: 0px;
  @media all and (-ms-high-contrast: none) {
    _border-color: #dd8819 #000000 #000000 #000000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
  }
}

.hg__text {
  max-height: 8.4rem;
  @media (max-width: 768px) {
    max-height: 7.2rem;
  }
}


.transform.rotate-180 {
  -ms-transform: rotate(180deg);
}
