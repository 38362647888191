#review-carousel {
  .VueCarousel-dot-container {
    margin-top: -2rem!important;
  }

  .VueCarousel-dot {
    pointer-events: none;
    background: #E2E2E2 !important;
    padding: 5px !important;
    width: 0px !important;
    height: 0px !important;
    margin-right: 5px !important;

    &--active {
      background: #A7A7A7 !important;
    }
  }
}
