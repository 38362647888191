.button {
  background-color: theme('colors.primary.btn');
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  font-weight: 700;
  font-size: 1.8rem;
  border-radius: 0.5rem;
  position: relative;
  outline: none;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  &:focus {
    outline: none;
  }
  @responsive {
    &--h-larger {
      height: 6rem;
    }
  }
  &--green {
    background: linear-gradient(89.71deg, #21AB22 9.14%, #22C023 115.93%);
  }
  &--blue {
    background: #2768b3;
  }
  &--pink {
    background: linear-gradient(270deg, #B5256E -2.12%, #8F2A5E 100%);
  }
  &--cyan {
    background: linear-gradient(90.25deg, #0E61C2 -25.18%, #3589EC 101.59%);
  }
  &--greenish {
    background: linear-gradient(89.69deg, #21AB22 9.85%, #22C023 125.03%);
    transition: background 0.1s ease-in;

    &:hover {
      background: linear-gradient(269.86deg, #21AB22 0.12%, #20BF1A 99.88%);
    }
    &:active {
      background: #1EA21F
    }
  }
  &--lime {
    background: linear-gradient(89.71deg, #21AB22 9.14%, #22C023 115.93%);
    transition: background 0.1s ease-in;

    &:hover {
      background: linear-gradient(269.86deg, #21AB22 0.12%, #20BF1A 99.88%);
    }
    &:active {
      background: #1EA21F;
    }
  }
  &--border-bottom {
    box-shadow: 0 4px 0 0 #ccb200;
    //border-bottom-width: 0.4rem;
    //border-color: #ccb200;
    transition: box-shadow 0.1s ease-in;
    &:active {
      box-shadow: 0 2px 0 0 #ccb200;

    }
  }
  &--border-bottom-green {
    box-shadow: 0 4px 0 0 #2F9946;
    //border-bottom-width: 0.4rem;
    //border-color: #ccb200;
    transition: box-shadow 0.1s ease-in;
    &:active {
      box-shadow: 0 2px 0 0 #2F9946;
    }
  }
  &--border-bottom-pink {
    box-shadow: 0 4px 0 0 #902B5E;
    //border-bottom-width: 0.4rem;
    //border-color: #ccb200;
    transition: box-shadow 0.1s ease-in;
  }
  &--border-bottom-cyan {
    box-shadow: 0 4px 0 0 #0E61C2;
    //border-bottom-width: 0.4rem;
    //border-color: #ccb200;
    transition: box-shadow 0.1s ease-in;
  }
  &--border-bottom-greenish {
    box-shadow: 0px 2px 6px rgba(8, 176, 9, 0.19);
    transition: box-shadow 0.1s ease-in;
    &:hover {
      box-shadow: 0px 2px 6px rgba(8, 176, 9, 0.19);
    }
  }
  &--border-bottom-blue {
    box-shadow: 0 4px 0 0 #164882;
    //border-bottom-width: 0.4rem;
    //border-color: #ccb200;
    transition: box-shadow 0.1s ease-in;
    &:active {
      box-shadow: 0 2px 0 0 #164882;
    }
  }
  &:after {
    content: url('../../img/icons/arrow.svg');
    margin-left: 2rem;
    display: block;
    width: 3rem;
  }
  &--download {
    position: relative;
    top: 0.2rem;
    &:before {
      content: url('../../img/icons/download.svg');
      display: inline-block;
      max-width: 2rem;
      margin-right: 1rem;
      @media(max-width: 768px) {
        margin-right: 0.5rem;
      }
    }
  }
  &--no-arrow {
    &:after {
      display: none;
      content: '';
      margin-left: 0;
    }
  }

  &--arrow--white {
    &:after {
      content: url('../../img/icons/arrow_white.svg');
      margin-left: 1rem;
      height: 18px;
      display: block;
      width: 3rem;
    }
  }
}

@responsive {
  .bg-gradient-green {
    background-image: linear-gradient(0deg, #21ab22, #22c823);
  }
}

.loader {
  width: 3rem;
  height: 3rem;
  &:after {
    content: " ";
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 3px solid #000;
    animation: preloader 1.2s linear infinite;
  }
  &--black {
    &:after {
      border-color: #000 transparent #000 transparent;
    }
  }
  &--white {
    &:after {
      border-color: #fff transparent #fff transparent;
    }
  }
}

@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sk-chase {
  width: 3rem;
  height: 3rem;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
      transform: scale(1.0);
    }
}
.prev-fast-enter,
.prev-fast-leave-to {
  opacity: 0;
  transform: scale(0.4);
}
.prev-fast-leave-active {
  transition: 0s;
}
.prev-fast-enter-active {
  transition: 0.2s;
}

.btn-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  border-width: .2rem;
  // @apply border-primary;
  border-bottom-color: transparent;
  border-right-color: transparent;
  width: 2rem;
  height: 2rem;
  -webkit-animation: load .75s infinite;
  &--white {
    border-top-color: #FFFFFF;
    border-left-color: #FFFFFF;
  }
  &--black {
    border-top-color: #1F252B;
    border-left-color: #1F252B;
  }
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}


.store-favorite {
  &__button {
    max-width: 20.2rem;
    @media(max-width: 768px) {
      max-width: 25rem;
    }
  }
}
