#svg circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #F3F6DF;
  stroke-width: 1.5rem;
}

#svg #bar {
  stroke: #1EA73C;
  transform: rotate(-90deg);
  transform-origin: center;
}