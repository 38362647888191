$hamburger-padding-x: 0rem;
$hamburger-padding-y: 0rem;
$hamburger-layer-width: 3rem;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: theme('colors.black');
$hamburger-layer-border-radius: 0;
@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
  position: relative;
  &:focus {
    outline: none;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0.3rem;
    border-radius: 5px;
  }
}

.slide-leave-active, .slide-enter-active {
  transition-duration: 0.5s;
  //transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 600px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.slide-right-leave-active, .slide-right-enter-active {
  transition-duration: 0.5s;
}
.slide-right-enter-to, .slide-right-leave {
  transform: translateX(0);
}

.slide-right-enter, .slide-right-leave-to {
  transform: translateX(100%);
}


.nav-icon {
  &:hover {
    svg path {
      fill: #FFFFFF;
    }
  }
}

.icon-hover {
 &:hover {
   svg path {
     fill: #2b6db9;
   }
 }
  &--triggered {
    svg path {
      fill: #2b6db9 !important;
    }
  }
}

@variants hover, group-hover {
  .white-filter {
    filter: brightness(0) invert(1);
  }
}
