@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.10);
  }

  .text-shadow-md {
    text-shadow: 1px 1px 0 #072146;
  }

  .text-shadow-lg {
    text-shadow: 0 0.1rem 0.4rem #2d2d2d;
  }

  .text-shadow-none {
    text-shadow: none;
  }
}

.crossed-text {
  position: absolute;
  height: .3rem;
  background: #cb2248;
  width: 8rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) rotate(155deg);
}

#terms, #terms-no-modal {
  .level1, .level2, .level3 {
    list-style-type: none;
  }
  .level1 {
    counter-reset: level1;
  }
  .level2 {
    counter-reset: level2;
  }
  .level3 {
    counter-reset: level3;
  }
  li {
    display: block;
  }
  .level1 li > ol:before{
    height: 20px;
  }
  li:not(:last-child):after, li > ol:before{
    content: " ";
    display: block;
    position: relative;
    height: 10px;
    left: 0px; top: 100%;
  }
  .level1, .level2, .level3 {
    margin: 0;
    padding: 0;
  }
  li:before {
    left: 20px;
    position: absolute;
    width: 40px;
    display: inline-block;
  }
  .level1 > li:before {
    font-weight: bold;
  }
  .level3 > li:before {
    left: 85px;
  }
  .level1 > li:before {
    content: counter(level1)".";
    counter-increment: level1;
  }
  .level2 > li:before {
    content: counter(level1)"." counter(level2);
    counter-increment: level2;
  }
  .level3 > li:before {
    content: "(" counter(level3, lower-latin)") ";
    counter-increment: level3;
  }
}

#terms {
  .level1 > li, .level3 > li {
    padding-left: 40px;
  }
}

#terms-no-modal {
  .level1 > li, .level3 > li {
    padding-left: 50px;
  }
}

@responsive {
  .sign {
    &:after, &:before {
      content: url('../../img/icons/star.svg');
    }
  }
}

#deal-instructions {
  ol, ul {
    list-style: disc;
    list-style-position: inside;
  }
}

@responsive {
  %highlight {
    @apply relative z-10 inline-block;
    &:after {
      content: '';
      @apply absolute bottom-0 left-0 w-full rounded-md;
      height: 35%;
      z-index: -1;
    }
  }
  .highlight {
    &-blue-100 {
      @extend %highlight;
      &:after {
        @apply bg-blue-701;
      }
    }
    &-purple-300 {
      background: linear-gradient(180deg, rgba(255,255,255,0) 65%, theme('colors.purple.300') 65%);
      @apply rounded-md;
    }
    &-green-100 {
      @extend %highlight;
      &:after {
        @apply bg-green-100;
      }
    }
    &-h-8 {
      &:after {
        height: 2rem;
      }
    }
  }
}
