.checkout-table {
  overflow: hidden;

  li {
    &:before {
      float: left;
      width: 0;
      white-space: nowrap;
      content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . ";
    }
  }

  span:first-child {
    background: #fff;
  }

  span + span {
    float: right;
    padding-left: .33em;
    background: #fff;
  }
}
