#noty_layout__topRight {
  bottom: 0;
  width: 100%;
  left: 0;
  top: auto;
  right: 0;
  max-width: 128rem;
  margin: 0 auto;
  @screen md {
    top: 2rem;
    left: 0;
  }
  .noty_theme__mint.noty_type__error {
    padding: 1rem 2rem;
  }

  .noty_theme__mint.noty_type__alert {
    width: 100%;
    max-width: 120rem;
    background-color: #2F9946;
    padding: 1rem 2rem;
    border-radius: 0.6rem;
  }
  .noty_theme__mint.noty_bar .noty_body {
    color: white;
    font-size: 1.6rem;
    position: relative;
    &:after {
      content: 'x';
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem 0;
    }
    @screen md {
      font-size: 2rem;
    }
  }
}

#noty_layout__topCenter {
  max-width: 58rem;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 590px) {
    top: 0;
  }
  .noty_type__success {
    max-width: 58rem;
    margin: 0 auto;
    width: 100%;
    .noty_body {
      max-width: 58rem;
      margin: 0 auto;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      padding: 1.5rem 3.5rem 1.5rem 1.5rem;
      position: relative;
      display: flex;
      align-items: center;
      &:before {
        content: url('../../img/icons/success_white.png');
        position: relative;
        top: 0.4rem;
        margin-right: 1rem;
      }
      &:after {
        content: url('../../img/icons/close_white.png');
        position: absolute;
        top: 1.9rem;
        right: 1.5rem;
      }
    }
  }
  .noty_theme__mint.noty_type__success{
    background-color: #2F9946;
    border-bottom: 1px solid #2F9946;
  }
}
